import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import HelpdeskIcon from '../../assets/forms-help-desk.svg'
import TeamIcon from '../../assets/forms-team-fill.svg'
import USAIcon from '../../assets/forms-usa-flag.svg'
import LineIcon from '../../assets/forms-line.svg'
import SheetIcon from '../../assets/forms-spreadsheet.svg'
import WarehouseIcon from '../../assets/forms-warehouse.svg'
import ShieldIcon from '../../assets/forms-check-shield.svg'
import ULIcon from '../../assets/ul-icon.svg'

const StyledGatsbyImage = styled(GatsbyImage)`
  grid-area: 1/1;
  object-fit: cover;
  opacity: none;
  width: 100%;
  height: 420px;

  @media (max-width: 767px) {
    height: 650px;
  }
`

const Container = 'relative'
const innerDivTwo = 'bg-white border border-gray-200 hover:border-red-800 text-red-900 hover:text-red-800 flex items-center justify-between rounded shadow-md hover:shadow-xl cursor-pointer p-4'
const contentLeft = 'w-9/12 pr-2'
const contentRight = 'w-3/12'
const titleWrapper = 'flex items-center'
const Title = 'font-display text-2xl uppercase tracking-wider mb-2 leading-tight'
const description = 'font-body text-gray-800 text-sm'
const cardIcon = 'w-16 mx-auto'

const FormCard = ({ linkTo, formTitle, formDescription, formIcon }) => {
  const FormIcon = formIcon

  return (
    <div className={Container}>
      <a href={linkTo} target='_blank' rel='noreferrer noopener'>
        <div className={innerDivTwo}>
          <div className={contentLeft}>
            <div className={titleWrapper}>
              <h2 className={Title}>{formTitle}</h2>
            </div>

            <p className={description}>{formDescription}</p>
          </div>

          <div className={contentRight}>
            <FormIcon className={cardIcon} />
          </div>
        </div>
      </a>
    </div>
  )
}

FormCard.propTypes = {
  linkTo: PropTypes.string,
  formTitle: PropTypes.string,
  formDescription: PropTypes.string,
  formIcon: PropTypes.string,
}

const FormsSectionUpdateNew = () => {
  const data = useStaticQuery(graphql`
    query FormsSectionUpdateNewQuery {
      prismicFormsPage {
        data {
          section_title
          button_name
          button_link {
            url
          }
          background_image {
            gatsbyImageData(width: 1200)
            alt
          }
          first_form_title
          first_form_description
          first_form_hubspot_id
          second_form_title
          second_form_description
          second_form_hubspot_id
          third_form_title
          third_form_description
          third_form_hubspot_id
          fourth_form_title
          fourth_form_description
          fourth_form_hubspot_id
          documents {
            document_title
            document_description
            document_link {
              url
            }
            document_icon
          }          
        }
      }
      imageBg: file(relativePath: { eq: "sections/product-guide-bg-simple.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1200)
        }
      }
      imageLogo: file(relativePath: { eq: "sections/cat-icon.png" }) {
        childImageSharp {
          gatsbyImageData(width: 512)
        }
      }
    }
  `)

  const forms = data.prismicFormsPage.data
  const docs = data.prismicFormsPage
  const documents = docs.data.documents
  const imageBg = getImage(docs.data.background_image);
  const imageBgDefault = getImage(data.imageBg);
  const imageLogo = getImage(data.imageLogo);

  return (
    <div className='bg-white py-12'>
      <div className='w-11/12 xl:max-w-screen-xl flex flex-col items-center font-body justify-center mx-auto mb-8'>
        <h1 className='font-display text-4xl md:text-5xl text-red-900 text-center tracking-wider leading-tight'>Forms</h1>
      </div>

      <div className='w-11/12 xl:w-10/12 xl:max-w-screen-lg grid grid-cols-1 md:grid-cols-2 gap-6 mx-auto mb-12'>
        <div className={Container}>
          <Link to="/forms/customer-concern-form">
            <div className={innerDivTwo}>
              <div className={contentLeft}>
                <div className={titleWrapper}>
                  <h2 className={Title}>{forms.first_form_title}</h2>
                </div>

                <p className={description}>{forms.first_form_description}</p>
              </div>

              <div className={contentRight}>
                <LineIcon className={cardIcon} />
              </div>
            </div>
          </Link>
        </div>

        <div className={Container}>
          <Link to="/forms/new-product-request-form">
            <div className={innerDivTwo}>
              <div className={contentLeft}>
                <div className={titleWrapper}>
                  <h2 className={Title}>{forms.second_form_title}</h2>
                </div>

                <p className={description}>{forms.second_form_description}</p>
              </div>

              <div className={contentRight}>
                <LineIcon className={cardIcon} />
              </div>
            </div>
          </Link>
        </div>

        <div className={Container}>
          <Link to='/forms/new-price-list-request-form'>
            <div className={innerDivTwo}>
              <div className={contentLeft}>
                <div className={titleWrapper}>
                  <h2 className={Title}>{forms.third_form_title}</h2>
                </div>

                <p className={description}>{forms.third_form_description}</p>
              </div>

              <div className={contentRight}>
                <LineIcon className={cardIcon} />
              </div>
            </div>
          </Link>
        </div>

        <div className={Container}>
          <Link to='/forms/rma-form'>
            <div className={innerDivTwo}>
              <div className={contentLeft}>
                <div className={titleWrapper}>
                  <h2 className={Title}>{forms.fourth_form_title}</h2>
                </div>

                <p className={description}>{forms.fourth_form_description}</p>
              </div>

              <div className={contentRight}>
                <LineIcon className={cardIcon} />
              </div>
            </div>
          </Link>
        </div>
      </div>

      <div className='w-11/12 xl:max-w-screen-xl flex flex-col items-center font-body justify-center mx-auto mb-8'>
        <h2 className='font-display text-4xl md:text-5xl text-red-900 text-center tracking-wider leading-tight'>Documents</h2>
      </div>

      <div className='w-11/12 xl:max-w-screen-xl flex flex-col items-center font-body justify-center mx-auto mb-16 border-8 border-red-900'>
        <div className="w-full grid relative" style={{ position: 'relative' }}>
          <StyledGatsbyImage
            alt="section background"
            image={imageBg ? imageBg : imageBgDefault}
            formats={['auto', 'webp', 'avif']}
          />
          
          <div
            style={{
              gridArea: '1/1',
              position: 'relative',
              placeItems: 'center',
              display: 'grid',
            }}
          >
            <div>
              <div className='w-full mx-auto'>
                <div className="w-28 h-28 mx-auto mb-4">
                  <GatsbyImage 
                    alt="Command Access Logo"
                    image={imageLogo}
                    formats={['auto', 'webp', 'avif']}
                  />
                </div>

                <h2 className='text-site-yellow text-7xl text-center'>{docs.data.section_title ? docs.data.section_title : "Section Title"}</h2>

                <div className='flex items-center justify-center'>
                  <a href={docs.data.button_link ? docs.data.button_link.url : "https://www.commandaccess.com/forms"} target="_blank" rel='noopener noreferrer'>
                    <button className="bg-red-700 hover:bg-red-800 font-display text-white text-lg uppercase font-bold mt-4 py-3 px-6 mb-6 md:mb-0 rounded tracking-widest">
                      {docs.data.button_name ? docs.data.button_name : "Button Name"}
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='w-11/12 xl:w-10/12 xl:max-w-screen-lg grid grid-cols-1 md:grid-cols-2 gap-6 mx-auto'>

        {documents.map((item) => {
          return (
            <>
              {item.document_icon === "Help Desk Icon"
                ?
                <FormCard 
                  linkTo={item.document_link ? item.document_link.url : 'https://www.commandaccess.com'} 
                  formTitle={item.document_title ? item.document_title : 'Title Here'} 
                  formDescription={item.document_description ? item.document_description : 'Description here'} formIcon={HelpdeskIcon} 
                />
                : item.document_icon === "Team Icon"
                ?
                <FormCard 
                  linkTo={item.document_link ? item.document_link.url : 'https://www.commandaccess.com'} 
                  formTitle={item.document_title ? item.document_title : 'Title Here'} 
                  formDescription={item.document_description ? item.document_description : 'Description here'} formIcon={TeamIcon} 
                />
                : item.document_icon === "USA Icon"
                ?
                <FormCard 
                  linkTo={item.document_link ? item.document_link.url : 'https://www.commandaccess.com'} 
                  formTitle={item.document_title ? item.document_title : 'Title Here'} 
                  formDescription={item.document_description ? item.document_description : 'Description here'} formIcon={USAIcon} 
                />
                : item.document_icon === "Line Icon"
                ?
                <FormCard 
                  linkTo={item.document_link ? item.document_link.url : 'https://www.commandaccess.com'} 
                  formTitle={item.document_title ? item.document_title : 'Title Here'} 
                  formDescription={item.document_description ? item.document_description : 'Description here'} formIcon={LineIcon} 
                />
                : item.document_icon === "Sheet Icon"
                ?
                <FormCard 
                  linkTo={item.document_link ? item.document_link.url : 'https://www.commandaccess.com'} 
                  formTitle={item.document_title ? item.document_title : 'Title Here'} 
                  formDescription={item.document_description ? item.document_description : 'Description here'} formIcon={SheetIcon} 
                />
                : item.document_icon === "Warehouse Icon"
                ?
                <FormCard 
                  linkTo={item.document_link ? item.document_link.url : 'https://www.commandaccess.com'} 
                  formTitle={item.document_title ? item.document_title : 'Title Here'} 
                  formDescription={item.document_description ? item.document_description : 'Description here'} formIcon={WarehouseIcon} 
                />
                : item.document_icon === "Shield icon"
                ?
                <FormCard 
                  linkTo={item.document_link ? item.document_link.url : 'https://www.commandaccess.com'} 
                  formTitle={item.document_title ? item.document_title : 'Title Here'} 
                  formDescription={item.document_description ? item.document_description : 'Description here'} formIcon={ShieldIcon} 
                />
                : item.document_icon === "UL Icon"
                ?
                <FormCard 
                  linkTo={item.document_link ? item.document_link.url : 'https://www.commandaccess.com'} 
                  formTitle={item.document_title ? item.document_title : 'Title Here'} 
                  formDescription={item.document_description ? item.document_description : 'Description here'} formIcon={ULIcon} 
                />
                :
                null
              }
            </>
          )
        })}        
      </div>
    </div>
  )
}

export default FormsSectionUpdateNew