import React from "react"
import { useStaticQuery, graphql } from 'gatsby'

import MainContainer from "../../components/containers/MainContainer"
import HeadDataNew from "../../data/HeadDataNew"
import FormsSectionUpdateNew from "../../components/sections/FormsSectionUpdateNew"

const FormsPage = () => {
  const data = useStaticQuery(graphql`
    query FormsPageQueryNew {
      prismicFormsPage {
        data {
          meta_title
          meta_description
        }
      }
    }
  `)

  const doc = data.prismicFormsPage.data

  return (
    <MainContainer>
      <HeadDataNew
        title={doc.meta_title}
        description={doc.meta_description}
      />

      <FormsSectionUpdateNew />
    </MainContainer>
  )
}

export default FormsPage